
$(document).ready(function(){


    // $('body').on('change', '.lang-config-select select', function(){
    //     console.log('testtttt')
    //     $(this).parent().parent().parent().parent().addClass('hshshsh')
    //     var lang = $(this).children(':selected').attr('code');
    //     var id =  $(this).attr('data-id');
    //     if(typeof(id) != "undefined"){
    //         $('#'+id).find(".translated").addClass('hidden');
    //         $('#'+id).find('.form-translate-'+lang).removeClass('hidden').addClass('now-active');
    //     }else {
    //         $(this).parent().parent().parent().parent().find(".translated").addClass('hidden');
    //         $(this).parent().parent().parent().parent().find('.form-translate-'+lang).removeClass('hidden').addClass('now-active');
    //     }
       
    // });

    // $('body').on('change', '.lang-config-select select[data-id="translate_generic"]', function(){
    //     var lang = $(this).children(':selected').attr('code');
    //     var flag = $(this).parent().find('img').attr('src')
    //     $('#accordion .lang-config-select select').val(lang).change();
    //     $('#accordion .lang-config-select img').attr('src',flag)
    // });

   

    // $("body").on('click','.translated', function(){
    //     $(this).removeClass('now-active');
    // })

    $('body').on('change', '.custom-file-input', function(e){
        var fileName = e.target.files[0].name;
        $(this).parent().find('.custom-file-label').text(fileName);
    });

        $(document).on('scroll', function(){
            if($('#group-sticky').length){
                setTimeout(function(){
                    var scroll = $(document).scrollTop();
                    $('#group-sticky').css({'margin-top':(scroll>130)? scroll-120+'px': "10px"})
                },100)
            }
        })
    
    
    function speak(val) {
        var lang = $('#language-notif').val()
        if(lang == 'en'){
            responsiveVoice.speak(val , 'UK English Female');

        }else {
            responsiveVoice.speak(val , 'French Female');           
        }
    }
    $('body').on('click','#play-sound', function(){
        $('audio#runnotif')[0].play()
        setTimeout(()=>{
            speak( $('#text').val() )
        }, 1000)
    });


    
    
});



